// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plafondafwerking-glad-pleisterwerk-js": () => import("../src/pages/plafondafwerking/glad-pleisterwerk.js" /* webpackChunkName: "component---src-pages-plafondafwerking-glad-pleisterwerk-js" */),
  "component---src-pages-plafondafwerking-index-js": () => import("../src/pages/plafondafwerking/index.js" /* webpackChunkName: "component---src-pages-plafondafwerking-index-js" */),
  "component---src-pages-plafondafwerking-plafond-plint-js": () => import("../src/pages/plafondafwerking/plafond-plint.js" /* webpackChunkName: "component---src-pages-plafondafwerking-plafond-plint-js" */),
  "component---src-pages-plafondafwerking-schuurplafond-js": () => import("../src/pages/plafondafwerking/schuurplafond.js" /* webpackChunkName: "component---src-pages-plafondafwerking-schuurplafond-js" */),
  "component---src-pages-speciaal-stukwerk-beton-cire-js": () => import("../src/pages/speciaal-stukwerk/beton-cire.js" /* webpackChunkName: "component---src-pages-speciaal-stukwerk-beton-cire-js" */),
  "component---src-pages-speciaal-stukwerk-index-js": () => import("../src/pages/speciaal-stukwerk/index.js" /* webpackChunkName: "component---src-pages-speciaal-stukwerk-index-js" */),
  "component---src-pages-wandafwerking-behang-klaar-js": () => import("../src/pages/wandafwerking/behang-klaar.js" /* webpackChunkName: "component---src-pages-wandafwerking-behang-klaar-js" */),
  "component---src-pages-wandafwerking-glad-pleisterwerk-js": () => import("../src/pages/wandafwerking/glad-pleisterwerk.js" /* webpackChunkName: "component---src-pages-wandafwerking-glad-pleisterwerk-js" */),
  "component---src-pages-wandafwerking-index-js": () => import("../src/pages/wandafwerking/index.js" /* webpackChunkName: "component---src-pages-wandafwerking-index-js" */),
  "component---src-pages-wandafwerking-sierpleister-js": () => import("../src/pages/wandafwerking/sierpleister.js" /* webpackChunkName: "component---src-pages-wandafwerking-sierpleister-js" */)
}

